import React, { useContext, useEffect, useState } from 'react'
import ReactPlayer from 'react-player'
import { MediaModalType } from '../Const'
import { MediaModalContext } from '../../context/MediaModalContext'
import './MediaModal.css'

const getResponsiveWidthAndHeight = (requieredFrameWidth, requieredFrameHeight, videoFrameWidth, videoFrameHeight) => {
    let canvasRatio = requieredFrameWidth / requieredFrameHeight;
    let videoCamRatio = videoFrameWidth / videoFrameHeight;
    let targetWidth = 0;
    let targetHeight = 0;
    if (videoCamRatio > canvasRatio) {
        targetHeight = requieredFrameHeight;
        targetWidth = requieredFrameHeight * videoCamRatio;
    } else {
        targetWidth = requieredFrameWidth;
        targetHeight = (requieredFrameWidth) / videoCamRatio;
    }
    return { targetWidth, targetHeight }
}

const ModalLoaderAndClose = ({ className, children, closeMediaModal, showLoader }) => {
    return (
        <div className={className}>
            {children}
            <div className="mediaModal_container_closeBtn" onClick={(e) => {
                if (e) { e.preventDefault() }
                closeMediaModal()
            }}>
            </div>
            {
                showLoader &&
                <div className="mediaModal_container_loader">
                    <div className="lds-dual-ring"></div>
                </div>
            }
        </div>
    )
}

const IMAGE_RESTICT_TYPE = { WIDTH_RESTRICT: 0, HEIGHT_RESTRICT: 1, DEFAULT: -1 }
const DEFAULTDIMENSION = { width: 0, height: 0 }
export default function MediaModal() {
    const { closeMediaModal, mediaModalStatus, modalDetails } = useContext(MediaModalContext)
    const [targetDimension, setTargetDimension] = useState(DEFAULTDIMENSION)
    const [restricition, updateRestricition] = useState(IMAGE_RESTICT_TYPE.DEFAULT)
    const [imageLoaded, ToggleImageLoaded] = useState(false)
    useEffect(() => {
        if (modalDetails) {
            if (modalDetails.type === MediaModalType.Image) {
                console.log(modalDetails.link)
                let image = new Image()
                image.onload = (event) => {
                    console.log("loaded")
                    // if (window.innerWidth > 782) {
                    //     updateRestricition(IMAGE_RESTICT_TYPE.DEFAULT)
                    //     setTargetDimension({
                    //         width: image.width, height: image.height
                    //     })
                    //     return
                    // }
                    let targetHeight, targetWidth = 0;
                    let imageRatio = image.width / image.height
                    if (image.height > window.innerHeight) {
                        //check if we it will go out of window, if simple restriction are applied
                        let wr_height = Math.min(image.width, window.innerWidth * 0.8) / imageRatio
                        // alert(`${Math.min(image.width, window.innerWidth * 0.8)} ${imageRatio}, ${wr_height} || ${window.innerHeight * 0.8}`)
                        if (wr_height > window.innerHeight * 0.8) {
                            let desiredDimension = window.innerHeight * 0.8
                            targetHeight = Math.round(desiredDimension)
                            targetWidth = Math.round(desiredDimension * imageRatio)
                            setTargetDimension({
                                width: targetWidth, height: targetHeight
                            })
                            updateRestricition(IMAGE_RESTICT_TYPE.HEIGHT_RESTRICT)
                            ToggleImageLoaded(true)
                        } else {
                            updateRestricition(IMAGE_RESTICT_TYPE.WIDTH_RESTRICT)
                            ToggleImageLoaded(true)
                        }
                    } else {
                        updateRestricition(IMAGE_RESTICT_TYPE.WIDTH_RESTRICT)
                        ToggleImageLoaded(true)
                    }
                }
                image.src = modalDetails.link
            }
        } else {
            updateRestricition(IMAGE_RESTICT_TYPE.DEFAULT)
            setTargetDimension(DEFAULTDIMENSION)
            ToggleImageLoaded(false)
        }
    }, [modalDetails])

    return (
        <>
            {
                mediaModalStatus &&
                <>

                    <div className="mediaModal">
                        <div className="mediaModalCloser" onClick={(e) => {
                            if (e) { e.preventDefault() }
                            closeMediaModal()
                        }}></div>
                        {
                            modalDetails.type === MediaModalType.Image ?
                                (<>
                                    <ModalLoaderAndClose
                                        closeMediaModal={closeMediaModal}
                                        className={`image_modal ${restricition === IMAGE_RESTICT_TYPE.WIDTH_RESTRICT && 'w-image_modal'} ${restricition === IMAGE_RESTICT_TYPE.HEIGHT_RESTRICT && 'h-image_modal'}`}
                                    >
                                        {
                                            imageLoaded &&
                                            <img src={modalDetails.link} alt="imageLink"
                                                style={restricition === IMAGE_RESTICT_TYPE.HEIGHT_RESTRICT ? {
                                                    width: `${targetDimension.width}px`,
                                                    height: `${targetDimension.height}px`,
                                                } : {}}
                                            />
                                        }
                                    </ModalLoaderAndClose>
                                </>) : (
                                    <>
                                        <div className={`mediaModal_container ${modalDetails.type === MediaModalType.PDF ? 'pdf-full' : ''}`} style={modalDetails.type === MediaModalType.Component ? { width: 'auto' } : {}}>
                                            {
                                                modalDetails.type === MediaModalType.Videos &&
                                                <ReactPlayer
                                                    playing={true}
                                                    url={modalDetails.link}
                                                    playsinline={true}
                                                    volume={0.85}
                                                    controls={true}
                                                    width='100%'
                                                    height='100%'
                                                    style={{
                                                        background: 'black',
                                                        borderRadius: '0.5rem'
                                                    }}
                                                />
                                            }
                                            {
                                                modalDetails.type === MediaModalType.PDF &&
                                                <iframe src={modalDetails.link} title="pdfViewer" />
                                            }
                                            {
                                                modalDetails.type === MediaModalType.Component &&
                                                <modalDetails.link.component data={modalDetails.link.data} />
                                            }
                                            <div className="mediaModal_container_closeBtn" onClick={(e) => {
                                                if (e) { e.preventDefault() }
                                                closeMediaModal()
                                            }}>
                                            </div>
                                            <div className="mediaModal_container_loader">
                                                <div className="lds-dual-ring"></div>
                                            </div>
                                        </div>
                                    </>)
                        }

                    </div>
                </>
            }
        </>
    )
}
