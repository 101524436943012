import React, { Component } from 'react';
import { auth } from '../../firebase/firbase';
import { signInWithEmailAndPassword } from 'firebase/auth';

class LoginEmail extends Component {
	state = {
		email: '',
		password: '',
		errors: {
			email: '',
			password: '',
			region: ''
		}
	};

	handleInputChange = (event) => {
		const target = event.target;
		const value = target.type === 'checkbox' ? target.checked : target.value;
		const name = target.name;
		this.setState({ [name]: value });
	};

	redirectToHome = () => {
		const { history } = this.props;
		console.log();
		if (history) history.push('/home');
	};

	handleSubmit = (event) => {
		try {
			event.preventDefault();

			this.validateForm();

			if (!this.isValidForm(this.state.errors)) {
				console.log(this.state.errors);
				return;
			}

			this.setState({ showLoader: true });

			console.log(`${this.state.region}_${this.state.password}_${this.state.email}@event.com`);
			console.log(`${this.state.region}${this.state.password}${this.state.email}`);
			signInWithEmailAndPassword(auth, `${this.state.email}`, `${this.state.password}`)
				.then((confirmationResult) => {
					this.setState({ showLoader: false });
					window.confirmationResult = confirmationResult;
				})
				.catch((error) => {
					console.error(error);
					let errors = this.state.errors;

					if (error.email === 'auth/user-not-found') {
						errors.email = 'Please enter a valid email.';
					} else {
						errors.email = error.message;
					}
					this.setState({ errors: errors });
					this.setState({ showLoader: false });
				});
			//,,,,
		} catch (error) {
			console.error(error);
		}
	};

	validateForm = () => {
		let errors = this.state.errors;

		errors.email = this.state.email.length > 0 ? '' : 'Please enter a valid email.';
		errors.password = this.state.password.length > 0 ? '' : 'Please enter a valid password.';

		this.setState({ errors: errors });
	};

	isValidForm = (errors) => {
		let valid = true;
		Object.values(errors).forEach((val) => val.length > 0 && (valid = false));
		return valid;
	};

	onSwitchLogin = () => {
		if (this.props.onSwitchLogin) {
			this.props.onSwitchLogin();
		}
	};

	render() {
		return (
			<article className="loginBox">
				{this.state.showLoader && (
					<div className="page-level-loader">
						<img src="../../assets/images/loading.gif" alt="" />
					</div>
				)}

				<header className="loginBox__banner">
					<img src="../../assets/images/login-banner.jpg" alt="" />
				</header>

				<div className="loginBox__body">
					<img className="d-none d-lg-block mobile-logo" src="../../assets/images/logo-desktop.png" alt="" />
					<h1 className="loginBox__title mg-b60">Log In</h1>

					<form className="mg-b80" onSubmit={this.handleSubmit}>
						<div className="form-group">
							<label className="form-label">Enter your BD email address</label>
							<input
								type="text"
								name="email"
								value={this.state.email}
								onChange={this.handleInputChange}
								className="form-control"
								placeholder=""
							/>
							{this.state.errors.email && <span className="input-error">{this.state.errors.email}</span>}
						</div>

						<div className="form-group">
							<label className="form-label">Enter your password</label>
							<input
								type="password"
								name="password"
								value={this.state.password}
								onChange={this.handleInputChange}
								className="form-control"
								placeholder=""
							/>
							{this.state.errors.password && (
								<span className="input-error">{this.state.errors.password}</span>
							)}
						</div>

						<button id={'sign-in'} type={'submit'} className="btn btn-lg btn-primary btn-block">
							Login
						</button>
					</form>
				</div>

				<footer className="loginBox__footer">
					<picture>
						<img src="../../assets/images/logo.png" alt="Flowers" />
					</picture>
				</footer>
			</article>
		);
	}
}

export default LoginEmail;
