import React, { useState } from 'react'
import { exportFile } from '../firebase/firbase'
import { getSessionData } from '../firebase/firestore'
function validateDate(testdate) {
    var date_regex = /^(0[1-9]|1[0-2])-(0[1-9]|1\d|2\d|3[01])-(19|20)\d{2}$/;
    return date_regex.test(testdate);
}
export default function Analytics() {
    const [state, setState] = useState('')
    const [count, setcount] = useState('')
    const handleClick = async (e) => {
        if (e) { e.preventDefault() }
        let data = null
        if (state) {
            if (validateDate(state)) {
                if (count) {
                    console.log(state, count)
                    data = await getSessionData(state, count)
                } else {
                    data = await getSessionData(state, 0)
                }
            } else {
                alert("Date is not correct")
                return
            }
        } else {
            data = await getSessionData(null, count)
        }
        if (data) {
            data = Object.values(data)
            let newData = data.map(d => {
                if (d.TimeData) {
                    let timeData = { ...d.TimeData }
                    Object.keys(timeData).forEach(i => timeData[i] = (timeData[i] / 60).toFixed(2))
                    delete d.TimeData
                    d = {
                        ...d,
                        ...timeData
                    }
                    d['overAllTime'] = (d.total / 60).toFixed(2)
                    delete d.total
                }
                return d
            })

            // console.log(newData)
            exportFile(newData, "analytics", "BD_PS_Analytics.xlsx")
        }
    }
    return (
        <div >
            <br />
            <br />
            <h2>
                Start Date<br />
                (Enter <b>date</b> in mm-dd-yyyy format)
            </h2><br />
            <input type="text" placeholder='Date' value={state} onChange={(e) => { setState(e.target.value) }} /><br /><br />
            <h2>
                Number of entries<br />
                (All Enteries will be included if no input given)
            </h2><br />
            <input type="number" placeholder='00' value={count} onChange={(e) => { setcount(e.target.value) }} /><br /><br />
            <button onClick={handleClick}>Download Analytics</button>
        </div>
    )
}
