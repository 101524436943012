import React, { Component } from 'react';
import { MediaModalContext } from '../../context/MediaModalContext';
import HotspotDetails from '../hotspotDetails/HotspotDetails';

class RotateScreen extends Component {
	state = {
		selectedVariant: null,
		selectedHostSpot: null,
		showFeatures: true
	};

	componentDidMount() {
		this.setState({
			selectedVariant: this.props.product.variants.filter(
				(value) => value.id == this.props.product.defaultVariant
			)[0]
		});

		window.parent.hotspotTriggered = (hotspotId) => {
			console.log(hotspotId)
			let hotSpots = this.state.selectedVariant.hotSpots.filter((hotSpot) => {
				return hotSpot.id == hotspotId;
			});
			if (hotSpots.length > 0) {
				this.setState({ selectedHostSpot: hotSpots[0] });
			}
		};
	}

	onVariantClick = (event, variant) => {
		event.preventDefault();
		this.setState({ selectedVariant: variant });
		console.log("Vare", variant);
		if (this.state.selectedHostSpot)
			this.onCloseClick();

		if (window.parent.switchVariant) {
			console.log("Varient id :: ", variant.id);
			window.parent.switchVariant(variant.id);
		}
		if (this.props.notifyOnVariantChange) {
			this.props.notifyOnVariantChange(variant.name)
		}
	};

	onCloseClick = () => {
		this.setState({ selectedHostSpot: null });

		if (window.parent.zoomOut) {
			window.parent.zoomOut();
		}
	};

	handleInputChange = (event) => {
		const target = event.target;
		const value = target.type === 'checkbox' ? target.checked : target.value;
		const name = target.name;

		this.setState({
			[name]: value
		});

		if (window.parent.hotspotState) {
			// console.log(window.parent.hotspotState);
			// console.log(this.state.showFeatures);
			window.parent.hotspotState(!this.state.showFeatures);
		}
	};

	onPlusClick = (event) => {
		event.preventDefault();
		if (window.parent.onPlusClick) {
			window.parent.onPlusClick();
		}
	};

	onSearchClick = (event) => {
		event.preventDefault();
	};

	onMinusClick = (event) => {
		event.preventDefault();
		if (window.parent.onMinusClick) {
			window.parent.onMinusClick();
		}
	};

	render() {
		return (
			<section className="contentCntr">
				{/* <div className="page-level-loader">
                    <img src="assets/images/loading.gif" alt=""/>
                </div> */}

				<article className="productBox productBox__details">
					<div className={'productBox__wrapper' + (this.state.selectedHostSpot ? ' sidebar-active' : '')}>
						<div className="productBox__left">
							<div className="productBox__filter">
								<div className="productBox__filter-inner">
									<div className="filter-btn">
										<span className="filter-btn__text">Features</span>
										<label className="switch">
											<input
												type="checkbox"
												name={'showFeatures'}
												checked={this.state.showFeatures}
												onChange={this.handleInputChange}
											/>
											<span className="slider round" />
										</label>
									</div>
									<div className="filter-btn">
										<a
											className="filter-zoom"
											href="#"
											onClick={(event) => this.onMinusClick(event)}
										>
											<i className="icon icon-minus" />
										</a>
										<a
											className="filter-zoom"
											href="#"
											onClick={(event) => this.onSearchClick(event)}
										>
											<i className="icon icon-search" />
										</a>
										<a
											className="filter-zoom"
											href="#"
											onClick={(event) => this.onPlusClick(event)}
										>
											<i className="icon icon-plus" />
										</a>
									</div>
								</div>
							</div>
							<div className="productBox__slide">
								{this.state.selectedVariant && (
									<iframe src={this.props.product.modelUrl} width={'900'} height={'600'} />
								)}
							</div>
						</div>

						{this.state.selectedHostSpot && (
							<MediaModalContext.Consumer>
								{
									val =>
										<HotspotDetails
											mediaModalContext={val}
											hotspot={this.state.selectedHostSpot}
											onCloseClick={() => this.onCloseClick()}
										/>
								}
							</MediaModalContext.Consumer>
						)}
					</div>

					<footer className="productBox__footer">
						<div className="productBox__footer-list">
							{this.props.product.variants.length > 1 &&
								<>
									<ul>
										{
											this.props.product.variants.map((variant) => (
												<li
													key={variant.id}
													className={
														this.state.selectedVariant &&
															this.state.selectedVariant.id == variant.id ? (
															'active'
														) : (
															''
														)
													}
													onClick={(event) => this.onVariantClick(event, variant)}
												>
													<a href="#">{variant.name}</a>
												</li>
											))
										}
									</ul>
								</>
							}
						</div>
						<a href="#" className="productBox__footer-logo">
							<img src="assets/images/logo.png" alt="" />
						</a>
					</footer>
				</article>
				<article className="rotateBox">
					<div className="rotateBox__inner">
						<img
							className="rotateBox__pic"
							src="https://storage.googleapis.com/bd-webar-project-djis/assets/rotate-screen.png"
							alt=""
						/>
						<h2 className="rotateBox__title">Please rotate your device to start the experience.</h2>
					</div>
				</article>
			</section>
		);
	}
}

export default RotateScreen;
