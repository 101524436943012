import React, { Component } from "react";


class CustomerDetails extends Component {

    state = {
        customerName: "",
        accountName: "",
        errors: {
            customerName: "",
            accountName: "",
            region: "",
        }
    }


    handleInputChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        this.setState({ [name]: value });
    }

    redirectToHome = () => {
        const { history } = this.props;
        console.log()
        if (history) history.push("/home");
    }


    handleSubmit = (event) => {
        try {
            console.log("button clicked")
            event.preventDefault();

            this.validateForm();

            if (!this.isValidForm(this.state.errors)) {
                console.log(this.state.errors);
                return;
            }

            if (this.props.onCustomerEnter) {
                this.props.onCustomerEnter({ customerName: this.state.customerName, accountName: this.state.accountName });
            }
        } catch (error) {
            console.log(error)
        }
    }

    validateForm = () => {
        let errors = this.state.errors;

        errors.customerName = this.state.customerName.length > 0 ? '' : 'Please enter a valid customer name.';
        errors.accountName = this.state.accountName.length > 0 ? '' : 'Please enter a valid account name.';

        this.setState({ errors: errors });
    }

    isValidForm = (errors) => {
        let valid = true;
        Object.values(errors).forEach(val => val.length > 0 && (valid = false));
        return valid;
    }

    onSwitchLogin = () => {
        if (this.props.onSwitchLogin) {
            this.props.onSwitchLogin();
        }
    }


    render() {
        return (

            <article className="loginBox">

                {
                    this.state.showLoader &&
                    <div className="page-level-loader">
                        <img src="../../assets/images/loading.gif" alt=""></img>
                    </div>
                }

                <header className="loginBox__banner">
                    <img src="../../assets/images/login-banner.jpg" alt=""></img>
                </header>

                <div className="loginBox__body">
                    {/* <img className="d-none d-lg-block mobile-logo" src="../../assets/images/logo-desktop.png" alt=""></img> */}
                    <h1 className="loginBox__title mg-b60">Customer Details</h1>


                    <form className="mg-b80" onSubmit={this.handleSubmit}>

                        <div className="form-group">
                            <label className="form-label">Customer Name</label>
                            <input
                                type="text"
                                name="customerName"
                                value={this.state.customerName}
                                onChange={this.handleInputChange}
                                className="form-control"
                                placeholder="" />
                            {this.state.errors.customerName &&
                                <span
                                    className="input-error">{this.state.errors.customerName}</span>}
                        </div>

                        <div className="form-group">
                            <label className="form-label">Account Name</label>
                            <input
                                type="text"
                                name="accountName"
                                value={this.state.accountName}
                                onChange={this.handleInputChange}
                                className="form-control"
                                placeholder="" />
                            {this.state.errors.accountName &&
                                <span
                                    className="input-error">{this.state.errors.accountName}</span>}
                        </div>

                        <button id={"sign-in"} type={"submit"}
                            className="btn btn-lg btn-primary btn-block">Submit
                        </button>
                    </form>
                </div>


                <footer className="loginBox__footer">
                    <picture>
                        <img src="../../assets/images/logo.png" alt="Flowers" />
                    </picture>
                </footer>

            </article>);
    }

}

export default CustomerDetails;
