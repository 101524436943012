import React, { Component } from "react";
import { UserContext } from "../auth/providers";
import CustomerDetails from "../CustomerDetails/CustomerDetails";
import ProductList from "../productList/ProductList";
import Header from "../header/Header";
import ProductDetails from "../productDetails/ProductDetails";
import { database } from "../firebase/firbase";
import { collection, getDocs, setDoc, doc } from "firebase/firestore";
import { v4 as uuidv4 } from 'uuid';

import { addProduct, addProducts, fetchProducts } from '../firebase/firestore';
import ExitModal from "../ExitModal/ExitModal";
import MediaModal from "../MediaModal";
// import { product_details_1 } from "../data/product1";
// import { product_details_2 } from "../data/product2";
// import { product_details_3 } from "../data/product3";
// import { product_details_4 } from "../data/product4";
// import { product_details_5 } from "../data/product5";
// import { product_details_6 } from "../data/product6";
// import { product_details_7 } from "../data/product7";
// import { product_details_8 } from "../data/product8";

let products = [{
    id: 1,
    name: 'BD Totalys™ SlidePrep',
    description: '',
    imageUrl: 'assets/images/sample_product/product-small.png',
    modelUrl: '/ps-1/index.html',
    defaultVariant: 1.1,
    variants: [
        {
            id: 1.1,
            name: 'BD Totalys™ SlidePrep',
            hotSpots: [
                {
                    id: 1,
                    title: 'BD Totalys™ SlidePrep',
                    description: 'Liquid Crystal Display (LCD) and intuitive graphical touchscreen interface:',
                    tiles: [
                        {
                            id: 1,
                            url: 'assets/images/sample_product/image-70.png',
                            type: 'image',
                            points: [
                                {
                                    id: 1,
                                    text:
                                        'Presents all the information needed to view instrument status and operate it.'
                                }
                            ]
                        },
                        {
                            id: 2,
                            url: 'assets/images/sample_product/image-70.png',
                            type: 'image',
                            points: [
                                {
                                    id: 1,
                                    text: 'Provides automated and scheduled clean up processes'
                                }
                            ]
                        },
                        {
                            id: 3,
                            url: 'assets/images/sample_product/image-69.png',
                            type: 'image',
                            points: [
                                {
                                    id: 1,
                                    text: 'Processes BD SurePath™ and Non-Gynaecological samples'
                                }
                            ]
                        },
                        {
                            id: 4,
                            url: 'assets/images/sample_product/image-69.png',
                            type: 'image',
                            points: [
                                {
                                    id: 1,
                                    text: 'Option to select the desired run variation'
                                }
                            ]
                        }
                    ]
                },
                {
                    id: 2,
                    title: 'BD Totalys™ SlidePrep',
                    description: 'Slide tray support (STS) with slide trays and Deck accessories support rack (DASR).',
                    tiles: [
                        {
                            id: 1,
                            url: 'assets/images/sample_product/image-68.png',
                            type: 'image',
                            points: [
                                {
                                    id: 1,
                                    text:
                                        'Four STS modules can be loaded in a single run with an efficient throughput of 48 slides in less than 62 minutes.'
                                },
                                {
                                    id: 2,
                                    text: 'Each module are uniquely channeled to their corresponding slide tray.'
                                }
                            ]
                        },
                        {
                            id: 2,
                            url: 'assets/images/sample_product/image-68.png',
                            type: 'image',
                            points: [
                                {
                                    id: 1,
                                    text:
                                        'Four C-tube rack holders containing the enriched cell samples in c-tubes are loaded in DASR.'
                                },
                                {
                                    id: 2,
                                    text:
                                        'Each rack holder is uniquely pinned to hold their corresponding c-tube rack at designated positions.'
                                }
                            ]
                        }
                    ]
                },
                {
                    id: 3,
                    title: 'BD Totalys™ SlidePrep',
                    description: 'Slide tray support (STS) with slide trays and Deck accessories support rack (DASR)',
                    tiles: [
                        {
                            id: 1,
                            url: 'assets/images/sample_product/image-68.png',
                            type: 'image',
                            points: [
                                {
                                    id: 1,
                                    text:
                                        '450 μL of tris buffered water is added to each settling chamber, and tris buffered water is added to each c-tube.'
                                },
                                {
                                    id: 2,
                                    text:
                                        'A transfer tip is used to mix the cell pellet 8 times, then 200 μL is transferred to the settling chamber where it is allowed to settle for a minimum of 10 minutes. During this time cells bond with the BD SurePath PreCoat Slides to form a thin layer of cells.'
                                }
                            ]
                        },
                        {
                            id: 2,
                            url: 'assets/images/sample_product/image-68.png',
                            type: 'image',
                            points: [
                                {
                                    id: 1,
                                    text:
                                        'Rinsing steps with incubations are performed to remove unbound cells: 600 μL of alcohol blend rinse with incubation and 600 μL of tris buffered water with incubation.'
                                }
                            ]
                        },
                        {
                            id: 3,
                            url: 'assets/images/sample_product/image-68.png',
                            type: 'image',
                            points: [
                                {
                                    id: 1,
                                    text:
                                        '300 μL of hematoxylin and 300 μL of EA/OG are added to slides for Papanicolaou staining (interleaved with incubations, tris buffered water and alcohol blend rinse steps)'
                                }
                            ]
                        },
                        {
                            id: 4,
                            url: 'assets/images/sample_product/image-68.png',
                            type: 'image',
                            points: [
                                {
                                    id: 1,
                                    text:
                                        'For gynecological specimens, once samples are received from the collection site, cell enrichment is performed in order to remove obscuring debris such as blood and mucus to create an enriched pellet of diagnostically relevant cells. Cell enrichment consists of several steps including vortexing, processing using the BD PrepMate™ Automated Accessory, and centrifugation. Alternatively, the steps of cell enrichment can be completed using the fully automated BD Totalys Multiprocessor (MultiProcessor).'
                                }
                            ]
                        }
                    ]
                },
                {
                    id: 4,
                    title: 'BD Totalys™ SlidePrep',
                    description: 'Bulk reagent station.',
                    tiles: [
                        {
                            id: 1,
                            url: 'assets/images/sample_product/image-67.png',
                            type: 'image',
                            points: [
                                {
                                    id: 1,
                                    text:
                                        'Holds the reagent bottles, some of which are reserved for future assay expansion.'
                                },
                                {
                                    id: 2,
                                    text:
                                        'In front of each bulk reagent are tubing and two ports for a quick connect/ disconnect of consumables.'
                                }
                            ]
                        },
                        {
                            id: 2,
                            url: 'assets/images/sample_product/image-67.png',
                            type: 'image',
                            points: [
                                {
                                    id: 1,
                                    text: '1. Tris buffered water TBW'
                                },
                                {
                                    id: 2,
                                    text: '2. Auxiliary (AUX) 3 reagent - future assay expantion use.'
                                },
                                {
                                    id: 3,
                                    text: '3. EA/OG stain.'
                                },
                                {
                                    id: 4,
                                    text: '4. Hematoxylin stain.'
                                }
                            ]
                        },
                        {
                            id: 3,
                            url: 'assets/images/sample_product/image-67.png',
                            type: 'image',
                            points: [
                                {
                                    id: 1,
                                    text: '5. BD Alcohol Blend Rise (alcohol blend rinse)'
                                },
                                {
                                    id: 2,
                                    text: '6. Auxiliary (AUX) 1 reagent - future assay expantion use.'
                                },
                                {
                                    id: 3,
                                    text: '7. Auxiliary (AUX) 2 reagent - future assay expantion use.'
                                },
                                {
                                    id: 4,
                                    text: '8. Cleaning Contrad solution'
                                },
                                {
                                    id: 5,
                                    text: '9. Cleaning (DI) water.'
                                }
                            ]
                        }
                    ]
                },
                {
                    id: 5,
                    title: 'BD Totalys™ SlidePrep',
                    description:
                        'Waste station and vacuum:  contains 3 bottles main waste, auxiliary waste & scrubbing muffler',
                    tiles: [
                        {
                            id: 1,
                            url: 'assets/images/sample_product/image-66.png',
                            type: 'image',
                            points: [
                                {
                                    id: 1,
                                    text:
                                        '1. Aux Waste -  Liquid waste is generated when reagents and rinse liquids are aspirated from the settling chambers, or during priming steps.'
                                },
                                {
                                    id: 2,
                                    text:
                                        '2. Scrubbing muffler -  The main waste container holds all reagent wastes and the auxiliary (aux) waste container is reserved for future assay expansion. '
                                }
                            ]
                        },
                        {
                            id: 2,
                            url: 'assets/images/sample_product/image-66.png',
                            type: 'image',
                            points: [
                                {
                                    id: 3,
                                    text:
                                        '3. Main - waste - The scrubbing muffler removes volatile organic fumes from the exhaust tubing. Along with the fume hood reduces the chance of exposure to hazardous substances. Thus, acting as a close system.'
                                }
                            ]
                        }
                    ]
                },
                {
                    id: 6,
                    title: 'BD Totalys™ SlidePrep',
                    description: 'Computer connections and ports.',
                    tiles: [
                        {
                            id: 1,
                            url: 'assets/images/sample_product/image-72.png',
                            type: 'image',
                            points: [
                                {
                                    id: 1,
                                    text: '1. Ports to bulk reagent station.'
                                },
                                {
                                    id: 2,
                                    text: '2. USB and Ethernet Ports.'
                                },
                                {
                                    id: 3,
                                    text: '3. Ports to waste station.'
                                }
                            ]
                        }
                    ]
                },
                {
                    id: 6,
                    title: 'BD Totalys™ SlidePrep',
                    description: 'Power switch',
                    tiles: [
                        {
                            id: 1,
                            url: 'assets/images/sample_product/image-73.png',
                            type: 'image'
                        }
                    ]
                }
            ]
        }
    ]
}];

// For firestore testing purpose.
const add = async () => {
    try {
        let resp = await addProducts(products);
    } catch (error) { }
}

// For firestore testing purpose.
const fetch = async () => {
    try {
        let resp = await fetchProducts(2);
        console.log(resp);
    } catch (error) { }
}

const DefaultModal = {
    show: false,
    data: null
}

const DEFAULT_TIME_VALUE = { total: 0 };
const DEFAULT_TIME_INCREMENT_VALUE = 1000

class Home extends Component {


    state = {
        products: [],
        customerDetails: null,
        showExitCustomer: false,
        selectedProduct: null,
        exitModalVisible: false
    }

    timeData = DEFAULT_TIME_VALUE
    variantName = ''
    timerRef = null

    componentDidMount() {
        window.Home = this;
        // addProducts([product_details_1, product_details_2, product_details_3, product_details_4, product_details_5, product_details_6, product_details_7, product_details_8]);
        // addProducts([product_details_3, product_details_5]);
        // addProducts([product_details_7]);
        // addProducts([product_details_2, product_details_3, product_details_4, product_details_5]);
        this.getProduct();

        // window.onblur = (event) => {
        //     console.log(event)
        //     console.log(this.lastBlurTimestamp)
        //     const exceuSaveSessionData = () => {
        //         this.lastBlurTimestamp = new Date().getTime()
        //         this.saveUserSessionData()
        //     }
        //     if (!this.lastBlurTimestamp) {
        //         exceuSaveSessionData();
        //     } else {
        //         let currentTime = new Date().getTime()
        //         let diff = (currentTime - this.lastBlurTimestamp) / 1000
        //         console.log(diff)
        //         if (diff > 60) {
        //             exceuSaveSessionData();
        //         } else {
        //             console.log("TO early")
        //         }
        //     }
        // }
    }

    //#region  TimerFunction

    handleVariantChangeForTimerFunction = (name) => {
        this.variantName = name
    }

    IncrementTimer = () => {
        let inc_val = DEFAULT_TIME_INCREMENT_VALUE / 1000
        this.timeData.total += inc_val
        if (this.state.selectedProduct) {
            let activeProductName = ''// this.state.selectedProduct.name

            if (this.state.selectedProduct.variants.length === 1) {
                this.variantName = this.state.selectedProduct.variants[0].name
            } else {
                if (!this.variantName) {
                    this.variantName = this.state.selectedProduct.variants[0].name
                }
            }

            activeProductName += this.variantName

            if (!this.timeData.hasOwnProperty(activeProductName)) {
                this.timeData[activeProductName] = 1
            } else {
                this.timeData[activeProductName] += inc_val
            }
        } else {
            this.variantName = ''
        }
    }

    stopTimer = () => {
        clearInterval(this.timerRef)
    }

    startCustomerTimer = () => {
        this.timeData = { ...DEFAULT_TIME_VALUE };
        this.timerRef = setInterval(this.IncrementTimer, DEFAULT_TIME_INCREMENT_VALUE);
    }
    //#endregion

    getProduct = async () => {

        const querySnapshot = await getDocs(collection(database, "products"));

        let products = [];
        querySnapshot.forEach((doc) => {
            // doc.data() is never undefined for query doc snapshots
            let product = doc.data();
            product.id = doc.id;
            products.push(product);
            // console.log(doc.id, " => ", doc.data());
        });

        // products.push(product_details_8)

        this.setState({ products: products });
    }

    onCustomerEnter = (customerDetails) => {
        this.customerEnter(customerDetails).then(() => {
            this.setState({ customerDetails: customerDetails })
            this.setState({ showExitCustomer: true })
            this.startCustomerTimer();
        }).catch(err => console.error(err));
    };

    customerEnter = (customerDetails) => {
        try {
            let cd = {};
            cd.representativeId = this.context.email;
            cd.accountName = customerDetails.accountName;
            cd.customerName = customerDetails.customerName;
            cd.sessionStartTimestamp = new Date();
            const sessionsRef = collection(database, "sessions");

            let sessionId = uuidv4()
            this.setState({ sessionId: sessionId })

            return setDoc(doc(sessionsRef, sessionId), cd);
        } catch (error) {
            console.error(error);
        }
    }

    saveUserSessionData = (isExitCall = false) => {
        if (this.state.sessionId) {
            const sessionsRef = doc(database, 'sessions', this.state.sessionId);
            let _data = { lastSessionUpdateTimestamp: new Date(), TimeData: this.timeData }
            if (isExitCall) {
                _data = {
                    ..._data,
                    sessionExitTimestamp: new Date()
                }
            }
            return setDoc(sessionsRef, _data, { merge: true });
        } else {
            return new Promise((res, rej) => res())
        }
    }

    onCustomerExit = () => {
        //stop timer for analytics
        this.stopTimer()
        this.saveUserSessionData(true).then(() => {
            this.setState({ customerDetails: null });
            this.setState({ showExitCustomer: false });
        });
    };

    onProductClick = (product) => {
        if (product.active) this.setState({ selectedProduct: product })
    }

    toggleExitModal = (val = true) => {
        this.setState({
            exitModalVisible: val,
        })
    }

    deselectProduct = () => {
        this.setState({ selectedProduct: null })
    }

    handleExitModalClick = (val) => {
        if (val) {
            this.deselectProduct()
        }
        this.toggleExitModal(false)
        this.saveUserSessionData().then(() => {
            console.log("saveUserSessionData")
        });
    }

    render() {
        return (<>
            <MediaModal />
            <Header onCustomerExit={() => this.onCustomerExit()}
                onBackClick={() => this.toggleExitModal()}
                backEnabled={this.state.selectedProduct != null}
                showExitCustomer={this.state.showExitCustomer}> </Header>

            {
                this.state.customerDetails == null &&
                <CustomerDetails
                    onCustomerEnter={(customerDetails) => this.onCustomerEnter(customerDetails)}></CustomerDetails>
            }

            {
                this.state.customerDetails && this.state.selectedProduct == null &&
                <ProductList products={this.state.products}
                    onProductClick={(product) => this.onProductClick(product)}></ProductList>
            }

            {
                this.state.customerDetails && this.state.selectedProduct != null &&
                <ProductDetails product={this.state.selectedProduct} notifyOnVariantChange={this.handleVariantChangeForTimerFunction}></ProductDetails>
            }

            {
                this.state.exitModalVisible &&
                <ExitModal handleClick={this.handleExitModalClick} />
            }
        </>)
    }

}

Home.contextType = UserContext;

export default Home;
