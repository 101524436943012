import React, { Component, createContext } from "react";
import UserContext from "./UserContext";
import { auth } from "../../firebase/firbase";

class UserProvider extends Component {

    state = {
        user: null
    };

    componentDidMount = async () => {
        auth.onAuthStateChanged(userAuth => {
            this.setState({ user: userAuth});
        });
    };

    render() {
        const { user } = this.state;

        return (
            <UserContext.Provider value={user}>
                {this.props.children}
            </UserContext.Provider>
        );
    }
}

export default UserProvider;
