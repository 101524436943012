
import React, { useContext } from 'react';
import logo from './logo.svg';

import './App.css';
import { UserProvider } from "./components/auth/providers";
import Application from "./components/application/Application";
import MediaModalContextProvider from './context/MediaModalContext';

class App extends React.Component {

  render() {
    return (
      <UserProvider>
        <MediaModalContextProvider>
          <Application />
        </MediaModalContextProvider>
      </UserProvider>
    );
  }
}

export default App;
