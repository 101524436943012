import React, { Component, useContext } from "react";

import Home from "../home/Home";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Login from "../auth/login/Login";

import { UserContext } from "../auth/providers";
import Analytics from "../Analytics";

class Application extends Component {

    render() {
        let user = this.context;

        return (

            <Router>
                <Switch>
                    <Route exact path="/">
                        {user ? <Home /> : <Login />}
                    </Route>
                    <Route exact path="/login">
                        {user ? <Home /> : <Login />}
                    </Route>
                    <Route exact path="/home">
                        {user ? <Home /> : <Login />}
                    </Route>
                    <Route exact path="/analytics">
                        {user ? <Analytics /> : <Login />}
                    </Route>

                </Switch>
            </Router>
        );
    }
}

Application.contextType = UserContext;


export default Application;
