import React, {Component} from "react";
import LoginUniqueCode from "./LoginEmail";

class Login extends Component {

    state ={
      otpLogin: true
    }

    constructor(props) {
        super(props);
    }

    switchLogin = () => {
        this.setState({otpLogin: !this.state.otpLogin});
    }


    render() {
        return (<div className="wrapper">


            <LoginUniqueCode onSwitchLogin={()=> this.switchLogin()}></LoginUniqueCode>


        </div>);
    }

}

export default Login;
