import React, { Component } from 'react';
import { UserContext } from '../auth/providers';
import { logOut } from '../firebase/firbase';

class Header extends Component {
	state = {
		open: false
	};
	componentDidMount() {
		// console.log(this.context);
		document.addEventListener('click', (ev) => {
			if (ev.target.id == 'b' || ev.target.id == 'c') return;

			this.close();
		});
	}

	onCustomerExit = () => {
		if (this.props.onCustomerExit) {
			this.props.onCustomerExit();
		}

		return new Promise((res, rej) => {
			console.log('Customer exited !!!');
			res('Customer Exited');
		});
	};

	open = () => {
		this.setState({ open: true });
	};

	close = () => {
		if (this.state.open) {
			this.setState({ open: false });
		}
	};

	onBackClick = (event) => {
		event.preventDefault();

		if (this.props.onBackClick) {
			this.props.onBackClick();
		}
	};
	render() {
		return (
			<header className={`headerBox ${this.props.backEnabled ? 'headerBox--blue' : ''}`}>
				{this.props.backEnabled && (
					<a href="#" onClick={(event) => this.onBackClick(event)} className="headerBox__back">
						<i className="icon icon-angle-back" /> Back
					</a>
				)}

				{!this.props.backEnabled && (
					<a href="#" className="headerBox__logo">
						<img src="assets/images/logo.png" alt="" />
					</a>
				)}

				<div className={`dropdown ${this.state.open ? 'dropdown--open' : ''}`}>
					<a href="#" className="dropdown__btn" id={'a'} onClick={(event) => this.open(event)}>
						<i className="icon icon-user" id={'b'} />
						<span id={'c'}>{this.context.displayName}</span>
					</a>
					<div className="dropdown__list">
						<div className="dropdown__list-head">
							{(this.context.displayName.split(' ').length > 1 &&
								this.context.displayName.split(' ')[1].length > 8) ||
							this.context.displayName.split(' ')[0].length > 8 ? (
								<h3 style={{ fontSize: '2.35em', width: '75%' }}>
									{this.context.displayName}
									<span>{this.context.email}</span>
								</h3>
							) : (
								<h3 style={{ fontSize: '3.35em', width: '75%' }}>
									{this.context.displayName} <span>{this.context.email}</span>
								</h3>
							)}
							<i className="icon icon-user" />
						</div>

						<div className="dropdown__list-footer">
							{this.props.showExitCustomer && (
								<button className="btn btn-primary btn-block" onClick={() => this.onCustomerExit()}>
									Exit Customer
								</button>
							)}
							<button
								className="btn btn-primary btn-block  mg-t30"
								onClick={() => {
									this.onCustomerExit().then(() => {
										console.log('Logging out');
										logOut();
									});
								}}
							>
								Logout
							</button>
						</div>
					</div>
				</div>
			</header>
		);
	}
}

Header.contextType = UserContext;

export default Header;
