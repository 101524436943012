import React, { Component } from 'react';
import Slider from 'react-slick';
import { MediaModalType } from '../Const';
import Disclaimer from '../Disclaimer';
import './index.css';

function SampleNextArrow(props) {
	const { onClick } = props;
	return (
		<button className="carousel__btn carousel__btn--next" onClick={onClick}>
			<i className="icon icon-arrow-right" />
		</button>
	);
}

function SamplePrevArrow(props) {
	const { onClick } = props;
	return (
		<button className="carousel__btn carousel__btn--prev" onClick={onClick}>
			<i className="icon icon-arrow-left" />
		</button>
	);
}

class HotspotDetails extends Component {
	state = {
		description: this.props.hotspot.tiles[0].description
			? this.props.hotspot.tiles[0].description
			: this.props.hotspot.description,
		activeSlide: 0
	};

	onCloseClick = (event, variant) => {
		if (this.props.onCloseClick) {
			this.props.onCloseClick();
		}
	};

	handleImageClick = (data) => {
		this.props.mediaModalContext.showMediaModal(MediaModalType.Image, data.url);
	};

	render() {
		const settings = {
			slidesToShow: 1,
			prevArrow: <SamplePrevArrow />,
			nextArrow: <SampleNextArrow />,
			afterChange: (current) => {
				this.setState({ activeSlide: current });
				if (this.props.hotspot.tiles[current].description) {
					this.setState({ description: this.props.hotspot.tiles[current].description });
				} else {
					this.setState({ description: this.props.hotspot.description });
				}
			}
		};

		return (
			<div className="productBox__right">
				{this.props.hotspot && (
					<div className="productBox__slide-details">
						<button className="productBox__slide-details-close" onClick={() => this.onCloseClick()}>
							<i className="icon icon-close" />
						</button>
						<h2 className="productBox__slide-details-title">{this.props.hotspot.title}</h2>
						<p className="productBox__slide-details-desc mg-sm-t20">{this.state.description}</p>

						<Slider className="productBox__carousel" {...settings}>
							{this.props.hotspot.tiles.map((tile) => (
								<img
									className="tile_images"
									onClick={() => this.handleImageClick(tile)}
									key={tile.id}
									src={tile.url}
									alt=""
								/>
							))}
						</Slider>

						{/* <div className='flex-container'>
							<div> */}

						{this.props.hotspot.tiles[this.state.activeSlide].title && (
							<h3 className="productBox__slide-tiles-title">
								{this.props.hotspot.tiles[this.state.activeSlide].title}
							</h3>
						)}
						<ul
							className={`productBox__slide-details-bullets ${this.props.hotspot.tiles[
								this.state.activeSlide
							].hideBullets
								? 'productBox__slide-details-nobullets'
								: ''}`}
						>
							{this.state.activeSlide != null &&
								this.props.hotspot.tiles[this.state.activeSlide].points.map((point) => (
									<li>
										<p key={point.id} className="productBox__slide-details-desc">
											{point.text}
										</p>
									</li>
								))}
						</ul>
						{this.props.hotspot.tiles[this.state.activeSlide].footer && (
							<h5
								className="productBox__slide-tiles-title"
								style={{
									fontSize: '1.15rem',
									marginTop: '2rem'
								}}
							>
								{this.props.hotspot.tiles[this.state.activeSlide].footer}
							</h5>
						)}
						<Disclaimer
							style={{
								fontSize: "1rem",
								marginTop:"auto"
							}}
						/>
					</div>
				)}
			</div>
		);
	}
}

export default HotspotDetails;
