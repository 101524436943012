import React from 'react'

export default function ExitModal({ handleClick }) {
    return (
        <div className="exit-intent">
            <div className="exit-intent__inner">
                <img className="exit-intent__pic" src="assets/images/exit.png" alt="" />
                <p className="exit-intent__desc">Are you sure you want to exit?</p>
                <div className="d-flex align-items-center justify-content-between">
                    <button className="btn btn-secondary" onClick={() => handleClick(true)}>Yes</button>
                    <button className="btn btn-primary" onClick={() => handleClick(false)}>No</button>
                </div>
            </div>
        </div>
    )
}
