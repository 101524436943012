import * as firestore from 'firebase/firestore';
import { getDoc, getDocs } from 'firebase/firestore';
import { database } from './firbase';

export const addProduct = async (product) => {
	if (!product) throw new Error(`Empty Product`);
	try {
		// First Add a product;
		if (!product.id) throw new Error(`Please pass in a product id `);
		const resp = await firestore.setDoc(firestore.doc(database, `products`, `${product.id}`), product);
		console.log(`Product successfully added.`);

		return resp;
	} catch (error) {
		console.log(`Error in addProduct ::: `, error.message, error.stack);
		throw error;
	}
};

export const addProducts = async (products) => {
	if (!products || !products.length) throw new Error(`Empty Products array`);
	try {
		products.forEach((product) => {
			if (!product.id) throw new Error(`Please suppy product name for ::: `, product);
		});
		console.log(`All products valid`);
		let promiseArray = [];
		products.forEach((product) => {
			promiseArray.push(firestore.setDoc(firestore.doc(database, `products`, `${product.id}`), product));
		});

		const resp = await Promise.all(promiseArray);
		console.log(`Added all products successfully`);

		return resp;
	} catch (error) {
		console.log(`Error in addProducts ::: `, error.message, error.stack);
		throw error;
	}
};

export const fetchProducts = async (id = null) => {
	try {
		const query = id
			? firestore.query(firestore.collection(database, 'products'), firestore.where('id', '==', id))
			: firestore.query(firestore.collection(database, 'products'));
		const products = await firestore.getDocs(query);
		let formatedProducts = [];
		products.forEach((doc) => {
			formatedProducts[doc.id] = doc.data();
		});

		if (!formatedProducts) throw new Error(`No todos found`);
		return formatedProducts;
	} catch (error) {
		console.log(`Error in fetchProducts ::: `, error.message, error.stack);
		throw error;
	}
};

export const getSessionData = (date, limit) => {
	return new Promise(async (res, rej) => {
		try {
			console.log(date, limit)
			let sessionsQuery = null
			if (date) {
				let newDate = new Date(date)
				if (limit) {
					console.log(limit)
					sessionsQuery = firestore.query(firestore.collection(database, 'sessions'), firestore.where('sessionStartTimestamp', '>=', firestore.Timestamp.fromDate(newDate)), firestore.limit(limit));
				} else {
					sessionsQuery = firestore.query(firestore.collection(database, 'sessions'), firestore.where('sessionStartTimestamp', '>=', firestore.Timestamp.fromDate(newDate)));
				}
			} else {
				if (limit) {
					console.log(limit)
					sessionsQuery = firestore.query(firestore.collection(database, 'sessions'), firestore.limit(limit));
				} else {
					sessionsQuery = firestore.query(firestore.collection(database, 'sessions'));
				}
			}
			console.log(sessionsQuery)
			const sessionData = await firestore.getDocs(sessionsQuery);
			let resultData = []
			sessionData.forEach((doc) => {
				let d = doc.data()
				if (d['sessionStartTimestamp']) {
					d['sessionStartTimestamp'] = new Date(d['sessionStartTimestamp'].seconds * 1000).toLocaleString()
				}
				if (d['sessionExitTimestamp']) {
					d['sessionExitTimestamp'] = new Date(d['sessionExitTimestamp'].seconds * 1000).toLocaleString()
				}
				resultData[doc.id] = d;
			});
			res(resultData)
		} catch (error) {
			console.error(error)
			rej(error)
		}
	})
}
