import React, { Component } from 'react';
import Disclaimer from '../Disclaimer';

class ProductList extends Component {
	onProductClick = (product) => {
		if (this.props.onProductClick) {
			this.props.onProductClick(product);
		}
	};

	render() {
		return (
			<section className="contentCntr">
				<article className="productBox">
					<div className="productBox__banner">
						<aside className="productBox__banner-text">
							<h1>IDS </h1>
							<h3>3D Product Showcase</h3>
						</aside>
						<aside className="productBox__banner-pic">
							<img src={'assets/images/product-list-banner.jpg'} alt="" />
							{/* <img src="https://storage.googleapis.com/bd-webar-project-djis/assets/hq2.png" alt="" /> */}
						</aside>
					</div>
					<h2 className="productBox__title">Select Product</h2>

					<ul className="productBox__list">
						{this.props.products.map((product) => (
							<li
								key={product.id}
								onClick={() => this.onProductClick(product)}
								className={product.active ? '' : 'disabled_product'}
							>
								<a href="#">
									<span className="productBox__list-pic">
										<img src={product.imageUrl} alt={product.description} />
									</span>
									<span>{product.name}</span>
								</a>
							</li>
						))}
					</ul>
					<Disclaimer
						style={{
							fontSize: '1.5rem'
						}}
					/>
				</article>
			</section>
		);
	}
}

export default ProductList;
