import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import { getAuth, GoogleAuthProvider, signInWithPopup, signOut } from 'firebase/auth';
import { getStorage } from 'firebase/storage';
import { getFirestore, initializeFirestore } from 'firebase/firestore';
import XLSX from "xlsx";

export const exportFile = (data, sheetName, filename, headerOrdering = null) => {
	const wb = XLSX.utils.book_new()
	let wsAll = null
	if (headerOrdering) {
		wsAll = XLSX.utils.json_to_sheet(data, {
			header: headerOrdering
		})
	} else {
		wsAll = XLSX.utils.json_to_sheet(data)
	}
	XLSX.utils.book_append_sheet(wb, wsAll, sheetName)
	XLSX.writeFile(wb, filename)
}

const firebaseConfig = {
	apiKey: 'AIzaSyCobSUMAUIyGy43AxPUeSAjsR2BsFwCNi0',
	authDomain: 'bd-productshowcase.firebaseapp.com',
	projectId: 'bd-productshowcase',
	storageBucket: 'bd-productshowcase.appspot.com',
	messagingSenderId: '331712431260',
	appId: '1:331712431260:web:61501da487d61e65739959',
	measurementId: 'G-BG0QK369LJ'
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

export const auth = getAuth(app);

export const firestore = getStorage(app);

export const database = initializeFirestore(app, {
	// experimentalAutoDetectLongPolling:  true,
	experimentalForceLongPolling: true
})
// getFirestore(); // if we have initalize the firestore without any firebase settings
console.log(database._getSettings())

export const stripeId =
	'pk_test_51HBKStKiu7Gwqs8chwAkVobt7tqjIHE44NVTWTCdzWgUspQIKAOvw9XcSoCn32fYkLPhi7zxtlxPtWLkK7VGBqqW00IV2GqaSh';

const provider = new GoogleAuthProvider();

export const signInWithGoogle = () => {
	signInWithPopup(auth, provider);
};

export const logOut = () => {
	signOut(auth);
};

